import React, { useState, useEffect, useRef } from "react";

import { RouteComponentProps } from "react-router-dom";
import { Form } from "react-final-form";
import { addMonths, format, isAfter } from "date-fns";

import Loading from "../../components/loading";
import NumberSitePicker, { INumberSitePickerSubmit } from "./NumberSitePicker";
import PageHead from "../../components/PageHead";
import MuiTextInput from "../../components/MuiComponents/MuiTextInput";
import { closeModal, openModal } from "../../components/MuiComponents/Popup";
import ConfirmationModal from "../../components/MuiComponents/ConfirmationModal";
import MuiAutocompleteSelect from "../../components/MuiComponents/MuiAutocompleteSelect";
import MuiTableWrapper from "../../components/MuiComponents/MuiTableWrapper";
import MuiTextInputMultiline from "../../components/MuiComponents/MuiTextInputMultiline";
import { openSnackbar } from "../../components/MuiComponents/Notifier";
import MuiDropdownDots from "../../components/MuiComponents/MuiDropdownDots";

import { theme } from "../../theme";
import { ThemeProvider } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Grid,
  Button,
  Tab,
  Tabs,
  Typography,
  CardHeader,
} from "@material-ui/core";

import MySettings from "../../static/settings";

import { IAuditTrail } from "../../interfaces/audit-trail";
import { IPageProps } from "../../interfaces/page-props";
import {
  INumber,
  INumberFaxToEmailRequest,
  INumberForwardRequest,
  INumberHttpRequest,
  INumberHttpResponse,
  INumberTracker,
} from "../../interfaces/number";
import { ICustomersSearchItem } from "../../interfaces/customer";

const set = new MySettings();

interface INumberPage extends RouteComponentProps<any>, IPageProps {}

enum PageState {
  Loading,
  DetailsTab,
  SiteTab,
  EHATab,
  TrackersTab,
  AuditTab,
}

interface INumberSiteTabProps {
  userType:string
  activeNumber:INumber
  siteChange: (data: INumberSitePickerSubmit) => void;
  billingSiteChange: (data: INumberSitePickerSubmit) => void;
}

const NumberSiteTab: React.FC<INumberSiteTabProps> = (props:INumberSiteTabProps) => {
  const [customers, setCustomers] = useState<ICustomersSearchItem[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      const getCustomers = async () => {
        const response = await set.resellerCustomerSearch("",false);
        response.items.sort((a,b)=>{
          return a.item.name.localeCompare(b.item.name)
        })
        setCustomers(response.items);
        setLoading(false);
      };
      getCustomers();
    } catch (error) {
      openSnackbar({
        message: "Something went wrong. Please try again.",
        type: "error",
      });
    }
  }, []);

  if (loading) return <Loading />;
  return (<>
    <Card>
      <CardHeader title="Site" />
      <CardContent>
        <NumberSitePicker
          emptyFirstOption={false}
          customers={customers}
          userType={props.userType}
          customersId={props.activeNumber.ownerId}
          customersSitesId={props.activeNumber.customersSitesId}
          submitFunction={props.siteChange}
        />
      </CardContent>
    </Card>
    <br />
    <Card>
      <CardHeader title="Outgoing Calls Site" />
      <CardContent>
        Allocate outgoing calls for this number to another site.
        <br /><br />
        <NumberSitePicker
          emptyFirstOption={true}
          customers={customers}
          userType={props.userType}
          customersId={props.activeNumber.billOutgoingCallsCustomersId ? props.activeNumber.billOutgoingCallsCustomersId : ''}
          customersSitesId={props.activeNumber.billOutgoingCallsCustomersSitesId ? props.activeNumber.billOutgoingCallsCustomersSitesId : ''}
          submitFunction={props.billingSiteChange}
        />
      </CardContent>
    </Card>
  </>);
}

const Number: React.FC<INumberPage> = (props) => {
  const [pageState, setPageState] = useState(PageState.Loading);

  const [activeNumber, setActiveNumber] = useState<INumber>({
    customersSitesId: "",
    ownerId: "",
    faxToEmail: "",
    deactivated: "",
    id: "",
    name: "",
    forwarding: "",
    ownerName: "",
    vrAnswerAs: "",
    vrSpecialInstructions: "",
  });
  const [activeTracker, setActiveTracker] = useState<INumberTracker>({
    notes: "",
    owner: "",
    area: "",
    uid: "",
    yhc: "",
    numbersId: "",
    numbersName: "",
  });
  const [auditTrail, setAuditTrail] = useState<IAuditTrail[]>([]);
  const [numberTrackers, setNumberTrackers] = useState<INumberTracker[]>([]);
  const [customers, setCustomers] = useState<string[]>([]);

  const isMounted = useRef(true);

  useEffect(() => {
    if (isMounted.current) {
      try {
        const initialRequest = async () => {
          const url = set.getNumberUrl();
          const numberRequest: INumberHttpRequest = {
            id: props.match.params.id,
          };

          const numbersData = await set.doAPIRequest<INumberHttpResponse>(
            url,
            numberRequest
          );

          setActiveTracker({
            ...activeTracker,
            numbersId: numbersData.item.id,
          });
          setActiveNumber(numbersData.item);
          setAuditTrail(numbersData.auditTrail ?? []);
          setNumberTrackers(numbersData.numberTrackers ?? []);

          const trackersData = await set.numberTrackersGet("", "", "");

          setCustomers(
            trackersData.items.map((tracker) => tracker.owner && tracker.owner)
          );

          setPageState(PageState.DetailsTab);
        };

        initialRequest();
      } catch (error) {
        openSnackbar({
          message: "Something went wrong. Please try again.",
          type: "error",
        });
      }
    }

    return () => {
      isMounted.current = false;
    };
  }, [props.match.params.id, activeTracker]);

  const auditColumns = [
    {
      name: "date",
      label: "Date",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => {
          const element = auditTrail[dataIndex];
          return format(new Date(element.date ?? ""), "yyyy-MM-dd HH:mm:ss");
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "userAgentName",
      label: "Agent Name",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const trackersColumns = [
    {
      name: "uid",
      label: "UID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "owner",
      label: "Customers",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "area",
      label: "Area",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "notes",
      label: "Notes",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: true,
        sort: true,
        customBodyRenderLite: (dataIndex: number) => {
          const element = numberTrackers[dataIndex];

          return (
            <MuiDropdownDots
              options={[
                {
                  label: "Edit Tracker",
                  onClick: async () =>
                    openModal({
                      content: (
                        <Card>
                          <CardContent>
                            <Form
                              initialValues={element}
                              onSubmit={async (values: INumberTracker) => {
                                try {
                                  setPageState(PageState.Loading);

                                  const result = await set.numberTrackerUpdate(
                                    values
                                  );

                                  setNumberTrackers(
                                    numberTrackers.map((tracker) =>
                                      tracker.id === result.id
                                        ? result
                                        : tracker
                                    )
                                  );

                                  setActiveTracker({
                                    notes: "",
                                    owner: "",
                                    area: "",
                                    uid: "",
                                    yhc: "",
                                    numbersId: activeNumber.id,
                                    numbersName: "",
                                  });

                                  closeModal();

                                  setPageState(PageState.TrackersTab);

                                  openSnackbar({
                                    message: "Tracker edited successfully.",
                                    type: "success",
                                  });
                                } catch (error) {
                                  openSnackbar({
                                    message:
                                      "Something went wrong. Please try again",
                                    type: "error",
                                  });
                                }
                              }}
                              render={({ handleSubmit, values }) => (
                                <form onSubmit={handleSubmit}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                      <Typography variant="h4" align="center">
                                        Edit tracker
                                      </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                      <MuiTextInput
                                        outlinedLabel="UID"
                                        name="uid"
                                      />
                                    </Grid>

                                    <Grid item xs={12}>
                                      <MuiTextInput
                                        outlinedLabel="YHC"
                                        name="yhc"
                                      />
                                    </Grid>

                                    {props.resellerID === undefined &&
                                      props.session.userType !==
                                        "customersContacts" && (
                                        <Grid item xs={12}>
                                          <MuiAutocompleteSelect
                                            name="owner"
                                            options={customers}
                                            label="Customer"
                                          />
                                        </Grid>
                                      )}

                                    <Grid item xs={12}>
                                      <MuiTextInput
                                        outlinedLabel="Area"
                                        name="area"
                                      />
                                    </Grid>

                                    <Grid item xs={12}>
                                      <MuiTextInput
                                        outlinedLabel="Notes"
                                        name="notes"
                                      />
                                    </Grid>

                                    <Grid item xs={12}>
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        type="submit"
                                      >
                                        Submit
                                      </Button>
                                    </Grid>

                                    <Grid item xs={12}>
                                      <Button
                                        color="secondary"
                                        fullWidth
                                        onClick={closeModal}
                                      >
                                        Cancel
                                      </Button>
                                    </Grid>
                                  </Grid>
                                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                                </form>
                              )}
                            />
                          </CardContent>
                        </Card>
                      ),
                    }),
                },
                {
                  label: "Delete Tracker",
                  onClick: () => {
                    openModal({
                      content: (
                        <ConfirmationModal
                          header={"Delete Tracker"}
                          message={
                            "Are you sure you want to delete this tracker?"
                          }
                          buttonText={"Delete"}
                          onConfirm={async () => {
                            try {
                              setPageState(PageState.Loading);

                              await set.numberTrackerRemove(element.id!);

                              setNumberTrackers(
                                numberTrackers.filter(
                                  (tracker) => tracker.id !== element.id
                                )
                              );

                              closeModal();

                              setPageState(PageState.TrackersTab);

                              openSnackbar({
                                message: "Tracker deleted successfully",
                                type: "success",
                              });
                            } catch (error) {
                              openSnackbar({
                                message:
                                  "Something went wrong. Please try again.",
                                type: "error",
                              });
                            }
                          }}
                        />
                      ),
                    });
                  },
                },
              ]}
            />
          );
        },
      },
    },
  ];

  const submitNewNumberTracker = async (tracker: INumberTracker) => {
    try {
      setPageState(PageState.Loading);

      const response = await set.numberTrackerAdd(tracker);

      setNumberTrackers([...numberTrackers, response]);
      setActiveTracker({
        notes: "",
        owner: "",
        area: "",
        uid: "",
        yhc: "",
        numbersId: activeNumber.id,
        numbersName: "",
      });

      closeModal();

      setPageState(PageState.TrackersTab);

      openSnackbar({
        message: "Tracker added successfully.",
        type: "success",
      });
    } catch (error) {
      openSnackbar({
        message: "Something went wrong. Please try again.",
        type: "error",
      });
    }
  };

  const saveForwarding = async (data: INumberForwardRequest) => {
    try {
      setPageState(PageState.Loading);

      const result = await set.numberForward({
        id: props.match.params.id,
        forwardingNumber: data.forwardingNumber,
        whisperMessagePlayerNumber: data.whisperMessagePlayerNumber,
        greetingMessagePlayerNumber:data.greetingMessagePlayerNumber
      });
      setActiveNumber(result);

      closeModal();

      setPageState(PageState.DetailsTab);

      openSnackbar({
        message: "Forwarding added successfully.",
        type: "success",
      });
    } catch (error) {
      openSnackbar({
        message: "Something went wrong. Please try again.",
        type: "error",
      });
    }
  };

  const cancelForwarding = async (number: INumber) => {
    try {
      setPageState(PageState.Loading);

      const result = await set.numberCancelForward(number.id);

      setActiveNumber(result);

      setPageState(PageState.DetailsTab);

      openSnackbar({
        message: "Forwarding cancelled successfully.",
        type: "success",
      });
    } catch (error) {
      openSnackbar({
        message: "Something went wrong. Please try again.",
        type: "error",
      });
    }
  };

  const saveFaxToEmail = async (data: INumberFaxToEmailRequest) => {
    try {
      setPageState(PageState.Loading);

      const result = await set.numberFaxToEmail({
        id: props.match.params.id,
        emailAddress: data.emailAddress,
      });
      setActiveNumber(result);

      closeModal();

      setPageState(PageState.DetailsTab);

      openSnackbar({
        message: "Fax To Email added successfully",
        type: "success",
      });
    } catch (error) {
      openSnackbar({
        message: "Something went wrong. Please try again.",
        type: "error",
      });
    }
  };

  const cancelFaxToEmail = async (e: any, number: INumber) => {
    try {
      setPageState(PageState.Loading);

      const result = await set.numberCancelFaxToEmail(number.id);

      setActiveNumber(result);

      setPageState(PageState.DetailsTab);

      openSnackbar({
        message: "Fax To Email cancelled successfully.",
        type: "success",
      });
    } catch (error) {
      openSnackbar({
        message: "Something went wrong. Please try again.",
        type: "error",
      });
    }
  };

  const saveNote = async (notes: string) => {
    try {
      setPageState(PageState.Loading);

      let number=await set.numberNotesUpdate({
        ...activeNumber,
        notes: notes,
      });
      setActiveNumber(number)
      setPageState(PageState.DetailsTab);

      openSnackbar({
        message: "Note updated successfully.",
        type: "success",
      });
    } catch (error) {
      openSnackbar({
        message: "Something went wrong. Please try again.",
        type: "error",
      });
    }
  };

  const saveVipReceptionist = async (
    vrAnswerAs: string,
    vrSpecialInstructions: string
  ) => {
    try {
      setPageState(PageState.Loading);

      let number=await set.numberNotesUpdate({
        ...activeNumber,
        vrAnswerAs: vrAnswerAs,
        vrSpecialInstructions: vrSpecialInstructions,
      });
      setActiveNumber(number)
      setPageState(PageState.DetailsTab);

      openSnackbar({
        message: "VIP Receptionist details added successfully.",
        type: "success",
      });
    } catch (error) {
      openSnackbar({
        message: "Something went wrong. Please try again.",
        type: "error",
      });
    }
  };

  const siteChange = async (data: INumberSitePickerSubmit) => {
    try {
      setPageState(PageState.Loading);

      const number=await set.resellerNumberChangeOwner(
        props.match.params.id,
        data.customersSitesId,
        ''
      );
      setActiveNumber(number)
      openSnackbar({
        message: "Site changed successfully.",
        type: "success",
      });
    } catch (error) {
      let message=''
      if(error && typeof error.data==='string'){
        message=error.data
      }
      openSnackbar({
        message: "Something went wrong: " + message,
        type: "error",
      });
    }
    setPageState(PageState.SiteTab)
  };

  const billingSiteChange = async (data: INumberSitePickerSubmit) => {
    try {
      setPageState(PageState.Loading);

      const number=await set.resellerNumberChangeOutgoingBillingSite(
        props.match.params.id,
        data.customersSitesId
      );
      setActiveNumber(number)
      openSnackbar({
        message: "Outgoing billing site changed successfully.",
        type: "success",
      });
    } catch (error) {
      let message=''
      if(error && typeof error.data==='string'){
        message=error.data
      }
      openSnackbar({
        message: "Something went wrong: " + message,
        type: "error",
      });
    }
    setPageState(PageState.SiteTab)
  };

  const saveEha = async (data: INumber) => {
    try {
      setPageState(PageState.Loading);

      const result = await set.numberEHAUpdate({
        ...activeNumber,
        ehaName: data.ehaName,
        ehaThoroughfare: data.ehaThoroughfare,
        ehaPremises: data.ehaPremises,
        ehaLocality: data.ehaLocality,
        ehaPostcode: data.ehaPostcode,
      });
      setActiveNumber(result);

      closeModal();

      setPageState(PageState.EHATab);

      openSnackbar({
        message: "EHA Information added successfully.",
        type: "success",
      });
    } catch (error) {
      openSnackbar({
        message: "Something went wrong. Please try again.",
        type: "error",
      });
    }
  };

  const RenderSwitch = () => {
    switch (pageState) {
      case PageState.Loading:
        return <Loading />;

      case PageState.DetailsTab:
        return (
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Card>
                <CardHeader title="Status" />

                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h6">
                        Status:{" "}
                        {activeNumber?.deactivated === ""
                          ? "Active"
                          : "Deactivated"}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      {activeNumber?.deactivated !== "" && (
                        <Typography variant="h6">
                          Number deactivated:{" "}
                          {activeNumber?.deactivated.replace("T", " ")}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      {activeNumber?.deactivated === "" ? (
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={async () => {
                            openModal({
                              content: (
                                <ConfirmationModal
                                  header="Deactivate Number"
                                  message="Are you sure you want to deactivate this number?"
                                  buttonText="Deactivate"
                                  onConfirm={async () => {
                                    try {
                                      setPageState(PageState.Loading);

                                      const result = await set.numberDeactivate(
                                        activeNumber.id
                                      );

                                      setActiveNumber(result);

                                      closeModal();
                                      setPageState(PageState.DetailsTab);

                                      openSnackbar({
                                        message:
                                          "Number deactivated successfully.",
                                        type: "success",
                                      });
                                    } catch (error) {
                                      openSnackbar({
                                        message:
                                          "Something went wrong. Please try again.",
                                        type: "error",
                                      });
                                    }
                                  }}
                                />
                              ),
                            });
                          }}
                        >
                          Deactivate
                        </Button>
                      ) : (
                        isAfter(
                          addMonths(new Date(activeNumber?.deactivated), 3),
                          new Date()
                        ) && (
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={async () => {
                              openModal({
                                content: (
                                  <ConfirmationModal
                                    header={"Reactivate Number"}
                                    message={
                                      "Are you sure you want to reactivate this number?"
                                    }
                                    buttonText={"Reactivate"}
                                    onConfirm={async () => {
                                      try {
                                        setPageState(PageState.Loading);

                                        const result =
                                          await set.numberReactivate(
                                            activeNumber.id
                                          );

                                        setActiveNumber(result);

                                        closeModal();

                                        setPageState(PageState.DetailsTab);

                                        openSnackbar({
                                          message:
                                            "Number reactivate successfully.",
                                          type: "success",
                                        });
                                      } catch (error) {
                                        openSnackbar({
                                          message:
                                            "Something went wrong. Please try again.",
                                          type: "error",
                                        });
                                      }
                                    }}
                                  />
                                ),
                              });
                            }}
                          >
                            Reactivate
                          </Button>
                        )
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardHeader title="Forwarding" />

                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {activeNumber?.whisperMessagePlayerNumber &&
                        activeNumber.whisperMessagePlayerNumber !== "" && (
                          <Typography variant="h6">
                            Whisper: {activeNumber?.whisperMessagePlayerNumber}
                          </Typography>
                        )}

                        {activeNumber?.greetingMessagePlayerNumber &&
                        activeNumber.greetingMessagePlayerNumber !== "" && (
                          <Typography variant="h6">
                            Greeting: {activeNumber?.greetingMessagePlayerNumber}
                          </Typography>
                        )}

                      {(activeNumber?.forwarding &&
                        activeNumber.forwarding !== "") ? (
                          <Typography variant="h6">
                            Forwarded: {activeNumber.forwarding}
                          </Typography>
                        ):(<Typography variant="h6">
                        This number is not currently forwarded.
                      </Typography>)}
                    </Grid>
                    <Grid item xs={12}>
                      {activeNumber?.forwarding &&
                      activeNumber.forwarding !== "" ? (
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={() => cancelForwarding(activeNumber)}
                        >
                          Cancel Forward
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() =>
                            openModal({
                              content: (
                                <Card>
                                  <CardContent>
                                    <Form
                                      onSubmit={saveForwarding}
                                      initialValues={{}}
                                      render={({ handleSubmit, values }) => (
                                        <form onSubmit={handleSubmit}>
                                          <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                              <Typography
                                                variant="h4"
                                                align="center"
                                              >
                                                Set Number Forwarding
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                              <MuiTextInput
                                                outlinedLabel="Phone number to forward to"
                                                name="forwardingNumber"
                                              />
                                            </Grid>

                                            <Grid item xs={12}>
                                              <MuiTextInput
                                                outlinedLabel="Whisper message player number. E.g 500"
                                                name="whisperMessagePlayerNumber"
                                                type="number"
                                              />
                                            </Grid>

                                            <Grid item xs={12}>
                                              <MuiTextInput
                                                outlinedLabel="Greeting message player number. E.g 500"
                                                name="greetingMessagePlayerNumber"
                                                type="number"
                                              />
                                            </Grid>

                                            <Grid item xs={12}>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                type="submit"
                                              >
                                                Submit
                                              </Button>
                                            </Grid>

                                            <Grid item xs={12}>
                                              <Button
                                                color="secondary"
                                                fullWidth
                                                onClick={closeModal}
                                              >
                                                Cancel
                                              </Button>
                                            </Grid>
                                          </Grid>

                                          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                                        </form>
                                      )}
                                    />
                                  </CardContent>
                                </Card>
                              ),
                            })
                          }
                        >
                          Set Forwarding
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardHeader title="Fax To Email" />
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {(activeNumber?.faxToEmail &&
                        activeNumber.faxToEmail !== "") ? (
                          <Typography variant="h6">
                            Fax To Email: {activeNumber.faxToEmail}
                          </Typography>
                        ):(
                          <Typography variant="h6">
                            This number does not currently have fax to email.
                          </Typography>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                      {activeNumber?.faxToEmail &&
                      activeNumber.faxToEmail !== "" ? (
                        <Button
                          color="secondary"
                          variant="outlined"
                          onClick={(event: any) =>
                            cancelFaxToEmail(event, activeNumber)
                          }
                        >
                          Cancel Fax To Email
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() =>
                            openModal({
                              content: (
                                <Card>
                                  <CardContent>
                                    <Form
                                      onSubmit={saveFaxToEmail}
                                      initialValues={{}}
                                      render={({ handleSubmit, values }) => (
                                        <form onSubmit={handleSubmit}>
                                          <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                              <Typography
                                                variant="h4"
                                                align="center"
                                              >
                                                Set Fax To Email
                                              </Typography>
                                            </Grid>

                                            <Grid item xs={12}>
                                              <MuiTextInput
                                                outlinedLabel="Email to forward faxes to number to"
                                                name="emailAddress"
                                              />
                                            </Grid>

                                            <Grid item xs={12}>
                                              <Button
                                                variant="contained"
                                                color="primary"
                                                fullWidth
                                                type="submit"
                                              >
                                                Submit
                                              </Button>
                                            </Grid>

                                            <Grid item xs={12}>
                                              <Button
                                                color="secondary"
                                                fullWidth
                                                onClick={closeModal}
                                              >
                                                Cancel
                                              </Button>
                                            </Grid>
                                          </Grid>

                                          {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                                        </form>
                                      )}
                                    />
                                  </CardContent>
                                </Card>
                              ),
                            })
                          }
                        >
                          Set Fax to Email
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardHeader title="Notes" />
                <CardContent>
                  <Form
                    onSubmit={({ notes }) => saveNote(notes)}
                    initialValues={{ notes: activeNumber.notes }}
                    render={({ handleSubmit, values }) => (
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <MuiTextInputMultiline
                              outlinedLabel="Notes"
                              name="notes"
                              multiline
                              fullwidth
                              rows={4}
                            />
                          </Grid>

                          <Grid item xs={2}>
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              type="submit"
                            >
                              Save Notes
                            </Button>
                          </Grid>
                        </Grid>

                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                      </form>
                    )}
                  />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Card>
                <CardHeader title="VIP Receptionist" />

                <CardContent>
                  <Form
                    onSubmit={({ vrAnswerAs, vrSpecialInstructions }) =>
                      saveVipReceptionist(vrAnswerAs, vrSpecialInstructions)
                    }
                    initialValues={{
                      vrAnswerAs: activeNumber.vrAnswerAs,
                      vrSpecialInstructions: activeNumber.vrSpecialInstructions,
                    }}
                    render={({ handleSubmit, values }) => (
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}>
                            <MuiTextInput
                              outlinedLabel="VIP Receptionist Greeting"
                              name="vrAnswerAs"
                              fullwidth
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <MuiTextInput
                              outlinedLabel="VIP Receptionist Special Instructions"
                              name="vrSpecialInstructions"
                              fullwidth
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <Button
                              variant="contained"
                              color="primary"
                              fullWidth
                              type="submit"
                            >
                              Save VIP Details
                            </Button>
                          </Grid>
                        </Grid>

                        {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                      </form>
                    )}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        );

      case PageState.SiteTab:
        return <NumberSiteTab userType={props.session.userType} activeNumber={activeNumber} siteChange={siteChange} billingSiteChange={billingSiteChange} />
      case PageState.EHATab:
        return (
          <Card>
            <CardHeader title="EHA Information" />
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    Name: {activeNumber.ehaName}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6">
                    Premises: {activeNumber.ehaPremises}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6">
                    Thoroughfare: {activeNumber.ehaThoroughfare}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6">
                    Postcode: {activeNumber.ehaPostcode}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="h6">
                    Locality: {activeNumber.ehaLocality}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() =>
                      openModal({
                        content: (
                          <Card>
                            <CardContent>
                              <Form
                                onSubmit={saveEha}
                                initialValues={{
                                  ehaName: activeNumber.ehaName,
                                  ehaPremises: activeNumber.ehaPremises,
                                  ehaThoroughfare: activeNumber.ehaThoroughfare,
                                  ehaPostcode: activeNumber.ehaPostcode,
                                  ehaLocality: activeNumber.ehaLocality,
                                }}
                                render={({ handleSubmit, values }) => (
                                  <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12}>
                                        <Typography variant="h4" align="center">
                                          Set EHA Infomation
                                        </Typography>
                                      </Grid>

                                      <Grid item xs={12}>
                                        <MuiTextInput
                                          outlinedLabel="Name"
                                          name="ehaName"
                                        />
                                      </Grid>

                                      <Grid item xs={12}>
                                        <MuiTextInput
                                          required={true}
                                          outlinedLabel="Premises (building number or name)"
                                          name="ehaPremises"
                                        />
                                      </Grid>

                                      <Grid item xs={12}>
                                        <MuiTextInput
                                          required={true}
                                          outlinedLabel="Thoroughfare (street name)"
                                          name="ehaThoroughfare"
                                        />
                                      </Grid>

                                      <Grid item xs={12}>
                                        <MuiTextInput
                                          required={true}
                                          outlinedLabel="Locality (town or city)"
                                          name="ehaLocality"
                                        />
                                      </Grid>

                                      <Grid item xs={12}>
                                        <MuiTextInput
                                          required={true}
                                          outlinedLabel="Postcode"
                                          name="ehaPostcode"
                                        />
                                      </Grid>

                                      <Grid item xs={12}>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          fullWidth
                                          type="submit"
                                        >
                                          Submit
                                        </Button>
                                      </Grid>

                                      <Grid item xs={12}>
                                        <Button
                                          color="secondary"
                                          fullWidth
                                          onClick={closeModal}
                                        >
                                          Cancel
                                        </Button>
                                      </Grid>
                                    </Grid>

                                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                                  </form>
                                )}
                              />
                            </CardContent>
                          </Card>
                        ),
                      })
                    }
                  >
                    Edit EHA Information
                  </Button>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );

      case PageState.TrackersTab:
        return (
          <MuiTableWrapper
            title="Trackers"
            data={numberTrackers}
            columns={trackersColumns}
            options={{
              filterType: "checkbox",
              selectableRows: "none",
              responsive: "standard",
              selectToolbarPlacement: "none",

              elevation: 0,
              print: false,
              download: false,
              search: false,
              filter: false,
              viewColumns: false,
              sortOrder: {
                name: "date",
                direction: "asc",
              },
              customTableBodyFooterRender: function () {
                return (
                  <ThemeProvider theme={theme}>
                    <Button
                      color="primary"
                      variant="contained"
                      style={{ marginTop: "2rem", width: "16rem" }}
                      onClick={() =>
                        openModal({
                          content: (
                            <Card>
                              <CardContent>
                                <Form
                                  onSubmit={submitNewNumberTracker}
                                  initialValues={{
                                    notes: "",
                                    owner: "",
                                    area: "",
                                    uid: "",
                                    yhc: "",
                                    numbersId: activeNumber.id,
                                    numbersName: "",
                                  }}
                                  render={({ handleSubmit, values }) => (
                                    <form onSubmit={handleSubmit}>
                                      <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                          <Typography
                                            variant="h4"
                                            align="center"
                                          >
                                            Create tracker
                                          </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                          <MuiTextInput
                                            outlinedLabel="UID"
                                            name="uid"
                                          />
                                        </Grid>

                                        <Grid item xs={12}>
                                          <MuiTextInput
                                            outlinedLabel="YHC"
                                            name="yhc"
                                          />
                                        </Grid>

                                        {props.resellerID === undefined &&
                                          props.session.userType !==
                                            "customersContacts" && (
                                            <Grid item xs={12}>
                                              <MuiAutocompleteSelect
                                                name="owner"
                                                options={customers}
                                                label="Customer"
                                              />
                                            </Grid>
                                          )}

                                        <Grid item xs={12}>
                                          <MuiTextInput
                                            outlinedLabel="Area"
                                            name="area"
                                          />
                                        </Grid>

                                        <Grid item xs={12}>
                                          <MuiTextInput
                                            outlinedLabel="Notes"
                                            name="notes"
                                          />
                                        </Grid>

                                        <Grid item xs={12}>
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            type="submit"
                                          >
                                            Submit
                                          </Button>
                                        </Grid>

                                        <Grid item xs={12}>
                                          <Button
                                            color="secondary"
                                            fullWidth
                                            onClick={closeModal}
                                          >
                                            Cancel
                                          </Button>
                                        </Grid>
                                      </Grid>
                                      {/* <pre>
                                            {JSON.stringify(values, null, 2)}
                                          </pre> */}
                                    </form>
                                  )}
                                />
                              </CardContent>
                            </Card>
                          ),
                        })
                      }
                    >
                      Create New Tracker
                    </Button>
                  </ThemeProvider>
                );
              },
            }}
          />
        );
      case PageState.AuditTab:
        return (
          <MuiTableWrapper
            title="Audit Trail"
            data={auditTrail}
            columns={auditColumns}
            options={{
              filterType: "checkbox",
              selectableRows: "none",
              responsive: "standard",
              selectToolbarPlacement: "none",

              elevation: 0,
              print: false,
              download: false,
              search: false,
              filter: false,
              viewColumns: false,
              sortOrder: {
                name: "date",
                direction: "asc",
              },
            }}
          />
        );

      default:
        return <></>;
    }
  };

  return (
    <Grid container spacing={3} alignContent="flex-start">
      <Grid item xs={12}>
        <PageHead
          resellerID={props.resellerID}
          title={activeNumber?.name}
          parents={[{ title: "Numbers", url: "../../Numbers" }]}
        />
      </Grid>

      <Grid item xs={12}>
        <Tabs
          value={pageState}
          indicatorColor="primary"
          textColor="primary"
          onChange={(_event: React.ChangeEvent<{}>, newValue: number) => {
            setPageState(newValue);
          }}
          aria-label="tabs"
        >
          <Tab label="Details" value={PageState.DetailsTab} />
          <Tab label="Site" value={PageState.SiteTab} />
          <Tab label="EHA Information" value={PageState.EHATab} />
          <Tab label="Trackers" value={PageState.TrackersTab} />
          <Tab label="Audit Information" value={PageState.AuditTab} />
        </Tabs>
      </Grid>

      <Grid item xs={12}>
        <RenderSwitch />
      </Grid>
    </Grid>
  );
};

export default Number;
