import * as React from "react";
import PageHead from "../../components/PageHead";
import { Col, Accordion, Card, Button } from "react-bootstrap";
import MySettings from "../../static/settings";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FaIcon } from "@fortawesome/react-fontawesome";
import { IPageProps } from "../../interfaces/page-props";
import Loading from "../../components/loading";
import { useAccordionToggle } from "react-bootstrap/AccordionToggle";
import VipCheckbox from "../../components/VipCheckbox";
import { IAccessGroup } from "../../interfaces/access-group";
import { NOTIFICATION_TYPE } from "../../enum/notification-type";
import { INotification } from "../../interfaces/notification";
import { RouteComponentProps } from "react-router-dom";
import catchME from "../../catchME";
import App from "../../App";
library.add(faChevronDown, faChevronUp);
const set = new MySettings();

function sortPermissions(permissions:any[]){
  permissions.sort((a,b)=>{
    return a.N.localeCompare(b.N)
  })
  permissions.sort((a,b)=>{
    const aType=typeof a.V==='object'
    const bType=typeof b.V==='object'
    if(aType===bType){
      return 0
    }else if(aType && !bType){
      return -1
    }else{
      return 1
    }
  })
  for(let i=0;i<permissions.length;i++){
    if(permissions[i].D!=null){
      permissions[i].D=sortPermissions(permissions[i].D)
    }
    if(permissions[i].id!=null){
      permissions[i].id=i
    }else if(permissions[i].id2!=null){
      permissions[i].id2=i
    }
  }

  return permissions
}

function CustomToggle({ children, eventKey, data }: any) {
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    //     console.log('totally custom!', data);
    //  setAccoridanKey(eventKey);
  });

  //console.log(data);
  return (
    <Card.Header>
      <div className="d-flex flex-rowd-flex justify-content-between">
        <div onClick={decoratedOnClick}>
          {"" === eventKey ? (
            <FaIcon fixedWidth icon="chevron-up" />
          ) : (
            <FaIcon fixedWidth icon="chevron-down" />
          )}
          {data.N}
        </div>
        <div> {children}</div>
      </div>
    </Card.Header>
  );
}

export interface IPermssionState {
  loading: boolean;
  results: any[];
  accordian: string;
  group?: IAccessGroup;
}

interface IPermissionPage extends RouteComponentProps<any>, IPageProps {}
export default class PermissionPage extends React.Component<
  IPermissionPage,
  IPermssionState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      results: [],
      loading: true,
      accordian: "",
    };

    this.subSet.bind(this);
    this.save.bind(this);
  }

  public componentDidMount = () => {
    this.getPermissions();
  };
  // THE actual post
  public getPermissions = () => {
    const requestedID = this.props.match.params.id;

    if (this.props.session.userType === "resellersContacts") {
      if (this.props.resellerID) {
        set
          .getAccessGroups(this.props.resellerID)
          .then((groups: IAccessGroup[]) => {
            const index = groups.findIndex((ddd) => ddd.id === requestedID);

            const payload: any = { ...groups[index].permissions };

            let arrayOfPermissions: any[] = [];

            //  console.log(Object.getOwnPropertyNames(payload))

            Object.getOwnPropertyNames(payload).map((dd, id) => {
              let tempObject: any = { id: id, N: dd, D: [], VV: false };

              //   console.log(typeof payload[dd]);
              if (typeof payload[dd] !== "boolean") {
                const propNames = Object.getOwnPropertyNames(payload[dd]);
                const totalProps = propNames.length;
                let trueProps = 0;
                propNames.map((dds, id2) => {
                  const value = payload[dd][dds];
                  if (value === true) {
                    trueProps++;
                  }
                  tempObject.D.push({ id2: id2, N: dds, V: value });
                  return null;
                });

                if (trueProps === totalProps) {
                  tempObject.VV = true;
                }
              } else {
                delete tempObject.D;
                tempObject.VV = payload[dd];
              }
              arrayOfPermissions.push(tempObject);
              return null;
            });
            arrayOfPermissions=sortPermissions(arrayOfPermissions)
            this.setState({
              group: { ...groups[index] },
              loading: false,
              results: arrayOfPermissions,
            });
          })
          .catch((exception) => {
            console.log(exception);
            catchME(this, exception, false);
          });
      } else {
        set
          .resellerPortalAccessGroupsGet()
          .then((resp) => {
            const groups = resp.items;
            const index = groups.findIndex((ddd) => ddd.id === requestedID);

            const payload: any = { ...groups[index].permissions };

            let arrayOfPermissions: any[] = [];

            //  console.log(Object.getOwnPropertyNames(payload))

            Object.getOwnPropertyNames(payload).map((dd, id) => {
              let tempObject: any = { id: id, N: dd, D: [], VV: false };

              //   console.log(typeof payload[dd]);
              if (typeof payload[dd] !== "boolean") {
                const propNames = Object.getOwnPropertyNames(payload[dd]);
                const totalProps = propNames.length;
                let trueProps = 0;
                propNames.map((dds, id2) => {
                  const value = payload[dd][dds];
                  if (value === true) {
                    trueProps++;
                  }
                  tempObject.D.push({ id2: id2, N: dds, V: value });
                  return null;
                });

                if (trueProps === totalProps) {
                  tempObject.VV = true;
                }
              } else {
                delete tempObject.D;
                tempObject.V = payload[dd];
              }
              arrayOfPermissions.push(tempObject);
              return null;
            });
            arrayOfPermissions=sortPermissions(arrayOfPermissions)
            this.setState({
              group: { ...groups[index] },
              loading: false,
              results: arrayOfPermissions,
            });
          })
          .catch((exception) => {
            console.log(exception);
            catchME(this, exception, false);
          });
      }
    } else {
      set
        .getAccessGroups(this.props.resellerID)
        .then((groups: IAccessGroup[]) => {
          const index = groups.findIndex((ddd) => ddd.id === requestedID);

          const payload: any = { ...groups[index].permissions };

          let arrayOfPermissions: any[] = [];

          //  console.log(Object.getOwnPropertyNames(payload))

          Object.getOwnPropertyNames(payload).map((dd, id) => {
            let tempObject: any = { id: id, N: dd, D: [], VV: false };

            //   console.log(typeof payload[dd]);
            if (typeof payload[dd] !== "boolean") {
              const propNames = Object.getOwnPropertyNames(payload[dd]);
              const totalProps = propNames.length;
              let trueProps = 0;
              propNames.map((dds, id2) => {
                const value = payload[dd][dds];
                if (value === true) {
                  trueProps++;
                }
                tempObject.D.push({ id2: id2, N: dds, V: value });
                return null;
              });

              if (trueProps === totalProps) {
                tempObject.VV = true;
              }
            } else {
              delete tempObject.D;
              tempObject.VV = payload[dd];
            }
            arrayOfPermissions.push(tempObject);
            return null;
          });
          arrayOfPermissions=sortPermissions(arrayOfPermissions)
          this.setState({
            group: { ...groups[index] },
            loading: false,
            results: arrayOfPermissions,
          });
        })
        .catch((exception) => {
          console.log(exception);
          catchME(this, exception, false);
        });
    }
  };

  public subSet = (value: boolean, element: any, subElement?: any) => {
    //  console.log(value, element, subElement);

    const { results } = this.state;
    let newResults = [...results];
    this.setState({ results: [] }, () => {
      const index = results.findIndex((ddd) => ddd.id === element.id);
      //  console.log(index);
      let parentNode = { ...results[index] };
      //   const c = results[index];
      if (subElement === undefined) {
        parentNode.VV = value;
        if (parentNode.D !== undefined) {
          let newD = parentNode.D.map((d: any) => {
            d.V = value;
            return d;
          });
          delete parentNode.D;
          parentNode.D = newD;
        }
      } else {
        //  console.log(parentNode);
        const index2 = parentNode.D.findIndex(
          (ddd: any) => ddd.id2 === subElement.id2
        );

        let childNode = { ...parentNode.D[index2] };
        childNode.V = value;

        parentNode.D[index2] = childNode;
      }
      newResults[index] = parentNode;
      this.setState({ results: newResults });
    });
  };

  public save() {
    //convert

    if (this.state.group !== undefined) {
      let update = this.state.group;
      const data: any[] = [...this.state.results];
      let json: any = {};
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        let obj: any = {};

        if (element.D !== undefined && element.D.length>0) {
          for (let index2 = 0; index2 < element.D.length; index2++) {
            const element2 = element.D[index2];
            obj[element2.N] = element2.V;
          }
        } else {
          obj = element.VV;
        }
        json[element.N] = obj;
      }

      update.permissions = json;

      if (this.props.session.userType === "resellersContacts") {
        if (this.props.resellerID) {
          set.portalAccessGroupsUpdate(update).then((updated) => {
            //   console.log(updated);
            const notification: INotification = {
              title: "Success",
              data: "Permissions saved.",
              variant: NOTIFICATION_TYPE.info,
              srcFunction: "componentDidUpdate",
              srcData: window.location.pathname,
              srcComponent: "App",
            };
            App.newNotification(notification, true);
          });
        } else {
          set.resellerPortalAccessGroupsUpdate(update).then((updated) => {
            //   console.log(updated);
            const notification: INotification = {
              title: "Success",
              data: "Permissions saved.",
              variant: NOTIFICATION_TYPE.info,
              srcFunction: "componentDidUpdate",
              srcData: window.location.pathname,
              srcComponent: "App",
            };
            App.newNotification(notification, true);
          });
        }
      } else {
        set.portalAccessGroupsUpdate(update).then((updated) => {
          //   console.log(updated);
          const notification: INotification = {
            title: "Success",
            data: "Permissions saved.",
            variant: NOTIFICATION_TYPE.info,
            srcFunction: "componentDidUpdate",
            srcData: window.location.pathname,
            srcComponent: "App",
          };
          App.newNotification(notification, true);
        });
      }
    }
  }

  public render() {
    const { loading, results, group } = this.state;

    let title = "Permission ";

    if (group !== undefined) {
      title = group.name;
    }

    return (
      <div className="content">
        <Col md="12" className="text-left">
          <PageHead
            resellerID={this.props.resellerID}
            title={title}
            parents={[{ title: "Permissions", url: "../permissions" }]}
          ></PageHead>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Accordion>
                {results.map((element, dIndex) => {
                  const indexD = dIndex.toString();
                  return (
                    <Card key={indexD}>
                      <CustomToggle eventKey={indexD} data={element}>
                        <VipCheckbox
                          field="V"
                          value={element.VV}
                          updateField={(ddd, bool) => {
                            //  console.log('sss', ddd);
                            this.subSet(bool, element);
                          }}
                          text=""
                        />
                      </CustomToggle>
                      <Accordion.Collapse eventKey={indexD}>
                        <Card.Body>
                          {element.D !== undefined && (
                            <>
                              {element.D.map(
                                (subDDD: any, subIndex: number) => {
                                  return (
                                    <div
                                      key={subIndex.toString()}
                                      className="d-flex flex-rowd-flex justify-content-between"
                                    >
                                      <div>{subDDD.N}</div>
                                      <div>
                                        <VipCheckbox
                                          field="V"
                                          value={subDDD.V}
                                          updateField={(ddd, bool) => {
                                            //  console.log('sss', ddd);
                                            this.subSet(bool, element, subDDD);
                                          }}
                                          text=""
                                        />
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                            </>
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              </Accordion>

              <Button
                onClick={() => {
                  this.save();
                }}
                className="noleft mt-3"
              >
                Save
              </Button>
            </>
          )}
        </Col>
      </div>
    );
  }
}
