import { IEmailSubReportCallPaths } from "../../interfaces/email-reports";
import { Grid } from "@material-ui/core";
import MuiTitle from "../misc/form/MuiTitle";
import VipTimeFieldMui from "../VipTimeFieldMui";
import { useState } from "react";
import MuiToggleBoolControlled from "../misc/form/MuiToggleBoolControlled";

export interface iEmailReportCallPathsProps {
  data: IEmailSubReportCallPaths;
  submitFunction: (field: string, data: any) => void;
}


const EmailReportCallPaths = ({ data, submitFunction }: iEmailReportCallPathsProps) => {
  const [state, setState] = useState<IEmailSubReportCallPaths>(() => {
    //  console.log(hotDesks)
    const xState = { ...data };
    if (!xState.startTime) {
      xState.startTime = "08:00:00"
      submitFunction("startTime",  "08:00:00")
    }
    if (!xState.endTime) {
      xState.endTime = "18:00:00"
      submitFunction("endTime", "18:00:00")
    }
    if (!xState.callPathsIncludeOutgoing) {
      xState.callPathsIncludeOutgoing = false;
      submitFunction("callPathsIncludeOutgoing", false)
    }

    if (!xState.callPathsShowFullExtensionName) {
      xState.callPathsShowFullExtensionName = false;
      submitFunction("callPathsShowFullExtensionName", false)
    }



    return xState;
  });
  const setField = (field: string, value: any) => {
    console.log("subform", field, value);
    const stateUp: any = { ...state };
    stateUp[field] = value;
    setState(stateUp);
    submitFunction(field, value);
  }
  return <Grid container spacing={2}>
    {/* <Grid item xs={12}>
  {JSON.stringify(data)}

</Grid> */}
    <Grid item xs={6}>
      <MuiTitle title="Start Time" />
      <VipTimeFieldMui
        field="startTime"
        value={state.startTime ? state.startTime : "08:00:00"}
        updateField={setField}
      />

    </Grid>
    <Grid item xs={6}>
      <MuiTitle title="End Time" />
      <VipTimeFieldMui
        field="endTime"
        value={state.startTime ? state.endTime : "18:00:00"}
        updateField={setField}
      />
    </Grid>
    <Grid item xs={6}>
      <MuiTitle title="Call Paths Include Outgoing" />
      <MuiToggleBoolControlled value={state.callPathsIncludeOutgoing} setValue={(ev) => {
        setField("callPathsIncludeOutgoing", !state.callPathsIncludeOutgoing);
      }} />

    </Grid>
    <Grid item xs={6}>
      <MuiTitle title="Call Paths Show Full Extension Name" />
      <MuiToggleBoolControlled value={state.callPathsShowFullExtensionName} setValue={(ev) => {
        setField("callPathsShowFullExtensionName", !state.callPathsShowFullExtensionName);
      }} />
    </Grid>

    <Grid item xs={6}>
      <MuiTitle title="Internal Calls Only" />
      <MuiToggleBoolControlled value={state.callPathsInternalCallsOnly} setValue={(ev) => {
        setField("callPathsInternalCallsOnly", !state.callPathsInternalCallsOnly);
      }} />
    </Grid>

  </Grid>
}

export default EmailReportCallPaths